<template>
  <div>
    <el-form class="el-form-custom" :model="formData" :inline="true">
      <el-form-item>
        <el-button @click="handlePoint" :class="!myUpload ? 'disable_icon' : ''">添加视频</el-button>
      </el-form-item>
    </el-form>
    <template v-if="tableData.length">
      <el-table :data="tableData" :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column align="center" label="视频编号" prop="videoName"></el-table-column>
      <el-table-column align="center" label="视频类型" prop="type"></el-table-column>
      <el-table-column align="center" label="摄像头型号" prop="model"></el-table-column>
      <el-table-column align="center" label="位置说明" prop="model"></el-table-column>
      <!--      <el-table-column label="添加时间" prop="createTime" min-width="230"></el-table-column>-->
      <el-table-column align="center" label="操作" width="180">
        <template slot-scope="scope">
          <!-- <svg class="icon view-icon" aria-hidden="true" @click="showDetail(scope.row.id)">
            <use xlink:href="#iconchakan" />
          </svg>
          <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleEdit(scope.row.id)">
            <use xlink:href="#iconbianji" />
          </svg>
          <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleDel(scope.row.id)">
            <use xlink:href="#iconshanchu" />
          </svg> -->
          <div class="btn-wrapper">
            <span type="text" class="colorText pointer" size="small" @click="showDetail(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">查看</span>
            <span type="text" class="colorText pointer" size="small" @click="handleEdit(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">编辑</span>
            <span type="text" class="colorText pointer" size="small" @click="handleDel(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>

  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
export default {
  components: {
    CommonPagination
  },
  props: {
    curPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      projectId: "",
      total: 0,
      currentPage: 1,
      disabled: false,
      formData: { typeGroupId: "", typeId: "" },
      typeOptions: [],
      targetOptions: [],
      statusOptions: [
        { id: 1, name: "是" },
        { id: 0, name: "否" }
      ],
      tableData: []
    };
  },
  mounted() {
    this.projectId = Number(this.$store.state.projectId);
    this.currentPage = this.curPage;
    console.log("返回this.currentPage", this.currentPage);
    this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;

    this.getPointTypes();
    this.getData();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ id: "", name: "全部" });
          this.typeOptions = res.data;
          this.getTargetOptions();
        }
      });
    },
    getTargetOptions() {
      this.$axios
        .get(`${this.baseURL}base/baseTargetType/byGroupId`, {
          typeGroupId: this.formData.typeGroupId
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            res.data.unshift({ id: "", name: "全部" });
            this.targetOptions = res.data;
          }
        });
    },
    getData(val) {
      console.log("返回getdata", val, this.currentPage);
      if (val) {
        this.currentPage = 1;
        this.$router.push({
          path: "/devManage",
          query: {
            currentPage2: this.currentPage,
            active: 2
          }
        });
      }
      this.$axios
        .get(`${this.baseURL}base/video/page/${this.currentPage}/10/${this.projectId}`, {
          params: this.formData
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            this.total = res.data.total;
            this.tableData = res.data.records;
          }
        });
    },
    resetData() {
      this.formData = { typeGroupId: "", typeId: "" };
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      console.log("视频分页", this.currentPage);
      this.$router.push({
        path: "/devManage",
        query: {
          currentPage2: this.currentPage,
          active: 2
        }
      });
      this.getData();
    },
    changeStatus(val) {
      if (this.total % 10 == 1) {
        this.currentPage -= 1;

        this.$router.push({
          path: "/devManage",
          query: {
            currentPage2: this.currentPage,
            active: 2
          }
        });
      }
      if (val.selectedRel == 1) {
        // 设为自选
        this.$axios.post(`${this.baseURL}base/baseTargetSelectedRel/${this.projectId}/${val.id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) this.getData();
        });
      } else {
        // 取消自选
        this.$axios.delete(`${this.baseURL}base/baseTargetSelectedRel/${val.id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) this.getData();
        });
      }
    },
    handlePoint() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      this.$router.push({
        path: "/setVideo",
        query: {
          currentPage: this.currentPage
        }
      });
    },
    showDetail(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$router.push("/ViewVideo/" + id); //ViewVideo  viewPoint
    },
    handleEdit(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      this.$router.push({
        path: "/setVideo",
        query: {
          id,
          type: "edit",
          currentPage: this.currentPage
        }
      });
    },
    handleDel(id) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      //  ZHW 删除视频，如果当前页只有一条，返回上一页
      let curpag = -1;
      if (this.tableData.length == 1 && this.currentPage > 1) {
        curpag = this.currentPage - 1;
      }

      this.$confirm("确认删除该视频类型？", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.get(`${this.baseURL}base/video/delete/${id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            if (curpag >= 0) {
              this.currentPage = curpag;
              this.$router.push({ path: "/devManage", query: { active: 2, currentPage: this.currentPage } });
            }
            this.getData();
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
td .icon {
  font-size: 22px;
  cursor: pointer;
}

td .view-icon {
  font-size: 24px;
  // color:rgb(134, 232, 255);
  // &:hover {
  //   color: #3997AD;
  // }
}

.icon:nth-child(2) {
  margin: 0 20px;
  // color: #DBF804;
  // &:hover {
  //   color:#98B300;
  // }
}

.icon:last-child {
  color: #f7f7f7;

  &:hover {
    color: #c3c3c3;
  }
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

.el-table {
  margin-bottom: 35px;
}

.finish {
  color: #86e8ff;
}

.el-form-item {
  margin: 0;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
