<template>
  <div class="home box box_column">
    <div class="box justify mB10 mB28">
      <div>
        <ul class="ulTab box center">
          <li v-for="(item, index) in list" :key="index" class="box center" @click="activeTab(item.id)">
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="jieduan"></div> -->
    <!-- 测点设备 -->
    <div v-if="active == 1">
      <DataList :curPage="curPage" :typeGroupId="typeGroupId" :typeId="typeId" :code="code"></DataList>
    </div>
    <div v-if="active == 2">
      <videoList :curPage="curPage2"></videoList>
    </div>
  </div>
</template>

<script>
import DataList from "./setDevInfo/DataList";
import videoList from "./setDevInfo/videoList";

export default {
  name: "home",
  components: {
    DataList,
    videoList
  },
  data() {
    return {
      curPage2: 2,
      curPage: 1,
      active: "1",
      list: [
        { id: "1", name: "测点设备" },
        { id: "2", name: "视频设备" }
      ]
    };
  },
  //创建
  created() {
    this.active = Number(this.$route.query.active) || 1;

    this.curPage = Number(this.$route.query.currentPage) || 1;
    this.curPage2 = Number(this.$route.query.currentPage2) || 1;

    this.typeGroupId = Number(this.$route.query.typeGroupId);
    this.typeId = Number(this.$route.query.typeId);
    this.code = this.$route.query.code || "";

    this.changeTab();
    // this.curPage = this.active == 1 ? curage : curage2;

    console.log("this.curPage", this.curPage, "curPage", this.curPage, "curPage2", this.curPage2);
  },

  //安装
  mounted() {
    console.log("devmanage-mounted");
  },

  //方法
  methods: {
    activeTab(id) {
      this.active = id;
      console.log("route", this.$route.path + "?");
      let currentpage = this.active == 1 ? `&currentPage=${this.curPage}&typeGroupId=${this.typeGroupId}&typeId=${this.typeId}&code=${this.code}` : `&currentPage2=${this.curPage2}`;
      this.$router.push(`${this.$route.path}?active=${this.active}${currentpage}`);
      this.changeTab();
    },
    changeTab() {
      let arr = this.$store.state.breadcrumbList;
      let path = "",
        name = "";
      if (this.active == 1) {
        name = "测点设备";
        path = `/devManage?currentPage=${this.curPage}&active=${this.active}&typeGroupId=${this.typeGroupId}&typeId=${this.typeId}&code=${this.code}`;
      } else {
        name = "视频设备";
        path = `/devManage?currentPage2=${this.curPage2}&active=${this.active}&typeGroupId=${this.typeGroupId}&typeId=${this.typeId}&code=${this.code}`;
      }

      if (arr.length >= 3) {
        arr.splice(2);
      }
      arr[1].path = path;
      arr.push({ name, path });
      this.$store.commit("getBreadcrumb", arr);
    }
  }
};
</script>
<style scoped lang="scss">
.home{
  margin-top: 20px;
}
.jieduan {
  width: 100%;
  height: 1px;
  box-shadow: 0px -1px 4px 0px #ffffff;
  opacity: 0.32;
  border: 1px solid #1f61ff;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ulTab {
  .fu {
    position: relative;
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }

  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}
</style>
