<template>
  <div>
    <el-form class="el-form-custom mB10" :model="formData" :inline="true">
      <el-form-item label="监测类型">
        <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptions">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="监测指标">
        <el-select v-model="formData.typeId" placeholder="请选择">
          <el-option v-for="item in targetOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="自选状态">
        <el-select v-model="formData.selectedRel" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"/>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-input v-model="formData.code" placeholder="请输入测点编号" />
      </el-form-item>
      <el-form-item>
        <el-button @click="getData('query')">查询</el-button>
        <el-button @click="resetData" style="background: none; border: 1px solid #2667db">
          <span>重置</span>
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="importHandle" v-if="isAdmin == 1" :class="!myOption ? 'disable_icon' : ''">导入</el-button>
        <el-button @click="handlePoint" :class="!myOption ? 'disable_icon' : ''">添加测点</el-button>
        <el-button @click="handleTreeOperate" :class="!myOption ? 'disable_icon' : ''">结构树设置</el-button>
      </el-form-item>
    </el-form>

    <!-- 结构树设置弹窗 -->
    <el-dialog title="测点结构树设置" :visible.sync="treeDialogVisible" class="my-dialog" top="10vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <div class="add-name">
          <span>结构树名称</span>
          <el-divider direction="vertical" class="my-divider"></el-divider>
          <span v-show="!isAddFlag">{{ viewName }}</span>
          <el-input v-show="isAddFlag" type="text" v-model.trim="viewName" placeholder="请输入结构树名称" @keyup.enter.native="handleAddView"></el-input>
        </div>
        <div class="list-wrapper">
          <el-tooltip class="item" effect="dark" content="新增结构树" placement="bottom">
            <i @click="handleAddIcon" class="el-icon-circle-plus"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="结构树列表" placement="bottom">
            <img @click="handleListBtnClick" src="@/assets/images/stress/list.png" alt="" />
          </el-tooltip>

          <ul v-show="isShowList" class="select-ul">
            <li v-for="(item, index) in viewList" :key="item.id" @click="handleViewClick(item)">
              <span class="name">
                <span v-if="currEditId != item.id">{{ item.viewName }}</span>
                <!-- <el-tooltip v-if="currEditId != item.id" class="item" effect="dark" :content="item.viewName" placement="bottom">
                  <span v-if="currEditId != item.id">{{ item.viewName }}</span>
                </el-tooltip> -->
                <span v-else>
                  <el-input class="my-edit-input" v-model.trim="inputValue" @keyup.enter.native="handleEnterEvent(item)" onkeyup="this.value=this.value.replace(/[, ]/g,'')"> </el-input>
                </span>
              </span>
              <span class="icon">
                <span v-show="index > 0" @click.stop="handleGoUp(item, index)">上移</span>
                <span v-show="index < viewList.length - 1" @click.stop="handleGoDown(item, index)">下移</span>
                <span @click.stop="handleEditIcon(item, index)">编辑</span>
                <span @click.stop="handleDeleteIcon(item, index)">删除</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="btns-wrapper">
        <div class="icon-wrapper" :class="activeItem == 'addParent' ? 'active-icon' : ''">
          <i class="icon iconfont icon-tianjia"></i>
          <!-- <img src="@/assets/images/stress/xinzeng.png" alt=""> -->
          <span @click="handleAddTop">新增节点</span>
        </div>
        <div v-show="isShowAddChild" class="icon-wrapper" :class="activeItem == 'addChild' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/zijiedian.png" alt=""> -->
          <i class="icon iconfont icon-zilinwu"></i>
          <span @click="handleAddChildNode">新增子节点</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'upgrade' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/shengji.png" alt=""> -->
          <i class="icon iconfont icon-shengji"></i>
          <span @click="handleUpgrade">升级</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'downgrade' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/jiangji.png" alt=""> -->
          <i class="icon iconfont icon-shengjibeifen2"></i>
          <span @click="handleDownGrade">降级</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'up' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/shangyi.png" alt=""> -->
          <i class="icon iconfont icon-shangyi"></i>
          <span @click="handleNodeUp">上移</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'down' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/xiayi.png" alt=""> -->
          <i class="icon iconfont icon-xiayi"></i>
          <span @click="handleNodeDown">下移</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'edit' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/bianji.png" alt=""> -->
          <i class="icon iconfont icon-bianji"></i>
          <span @click="handleEditTreeNode">编辑</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'delete' ? 'active-icon' : ''">
          <!-- <img src="@/assets/images/stress/shanchu.png" alt=""> -->
          <i class="icon iconfont icon-shanchu"></i>
          <span @click="handleDeleteTreeNode">删除</span>
        </div>
      </div>
      <div class="tree-wrapper">
        <div class="dialog-left-tree">
          <el-tree class="my-left-tree" :data="leftData" :default-expand-all="false" node-key="id" ref="leftTree" :default-checked-keys="defaultLeftKeys" :default-expanded-keys="defaultLeftExpandKeys" highlight-current @node-click="handleLeftNodeClick" :props="defaultLeftProps">
            <span style="width: 100%; line-height: 34px" slot-scope="{ node, data }" @dblclick="handleDblClick(node, data, $event)">
              <span v-if="currDblClickId != data.id">
                {{ node.label }}
              </span>
              <span v-else class="node-wrapper">
                <el-input class="my-edit-input" :value="dbClickInputValue" maxlength="20" onkeyup="this.value=this.value.replace(/[, ]/g,'')" @keyup.enter.native="handleViewEditSubmit(node, data)" @input="(a) => handleInputEvent(a, node, data)"> </el-input>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="dialog-btn">
          <div class="dialog-btn-group">
            <el-button @click="handleBtnFromLeft">从左边移动到组<img src="@/assets/images/stress/jiantou2.png" alt="" style="margin-left: 5px" /></el-button>
            <el-button @click="handleBtnFromRight"><img src="@/assets/images/stress/jiantou1.png" alt="" style="margin-right: 5px" />从右边移动到组</el-button>
          </div>
        </div>
        <div class="dialog-right-tree">
          <div class="search-input">
            <el-input v-model="rightKeywords" @input="handleRightKeyWordsInput" placeholder="请输入关键字" />
            <i class="el-icon-search" @click="handleRightFilter"></i>
          </div>
          <el-tree class="my-right-tree" :data="rightData" show-checkbox :default-expand-all="false" node-key="targetId" ref="rightTree" :default-expanded-keys="defaultRightKeys" highlight-current :props="defaultRightProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ data.targetName }}</span>
              <span v-if="data.children.length > 0">({{ data.children.length }})</span>
            </span>
          </el-tree>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <!-- <div v-if="type == 2" class="box center mB10" style="padding-left: 110px">
        <div class="fs16" style="color: #fff; margin-right: 40px">分数</div>
        <el-input v-model="fenshu"></el-input>
      </div> -->
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="180px">
        <!-- <el-form-item label="文件标题" prop="title">
              <el-input placeholder="请输入文件标题" v-model="ruleForm.title" />
            </el-form-item> -->
        <el-form-item label="文件附件" prop="file_arr">
          <el-upload action=" " multiple :limit="1" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="true" size="small" type="primary" class="select-btn">选择</el-button>
            <span slot="tip" class="el-upload__tip">只支持一个文件上传</span>
            <!-- <el-button v-show="!hideUploadAdd" size="small" type="primary">选择</el-button> -->
            <!-- <span slot="tip" class="el-upload__tip">上传文件不超过50M</span> -->
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="分数" prop="file_arr">
          <el-input v-model="fenshu"></el-input>
        </el-form-item> -->

        <el-form-item>
          <span class="sureBtn back-btn pointer mR30" @click="back">取消</span>
          <span class="backBtn submit-btn pointer" @click="submitForm">确定</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <template v-if="tableData.length">
      <el-table :data="tableData" :row-class-name="tableRowClassName" style="width: 100%" :height="tableHeight">
      <el-table-column align="center" label="测点编号" prop="code"></el-table-column>
      <el-table-column align="center" label="监测类型" prop="typeGroupName"></el-table-column>
      <el-table-column align="center" label="监测指标" prop="typeName"></el-table-column>
      <el-table-column align="center" label="传感器型号" prop="model"></el-table-column>
      <!--      <el-table-column label="系数" prop="coefficient"></el-table-column>-->
      <!--      <el-table-column label="单位" prop="unit"></el-table-column>-->
      <!--      <el-table-column label="精度" prop="precisions"></el-table-column>-->
      <el-table-column align="center" label="位置说明" prop="site"></el-table-column>
      <!-- <el-table-column label="设为自选" prop="selectedRel" width="100">
        <template slot-scope="scope">
          <el-switch
            :disabled="disabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value='1'
            :inactive-value='0'
            v-model="scope.row.selectedRel"
            @change="changeStatus(scope.row)">
          </el-switch>
        </template>
      </el-table-column> -->
      <!--      <el-table-column label="添加时间" prop="createTime" min-width="230"></el-table-column>-->
      <el-table-column label="操作" width="180px" align="center" label-class-name="option-btn">
        <template slot-scope="scope">
          <!-- <svg class="icon view-icon" aria-hidden="true" @click="showDetail(scope.row.id)">
            <use xlink:href="#iconchakan" />
          </svg>
          <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleEdit(scope.row.id)">
            <use xlink:href="#iconbianji" />
          </svg>
          <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleDel(scope.row.id)">
            <use xlink:href="#iconshanchu" />
          </svg> -->
          <span class="btn-wrapper">
            <span type="text" class="colorText pointer" size="small" @click="showDetail(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">查看</span>
            <span type="text" class="colorText pointer" size="small" @click="handleEdit(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">编辑</span>
            <span type="text" class="colorText pointer" size="small" @click="handleDel(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
export default {
  components: {
    CommonPagination
  },
  props: {
    curPage: {
      type: Number,
      default: 1
    },
    typeGroupId: {
      type: Number,
      default: ""
    },
    typeId: {
      type: Number,
      typeId: ""
    },
    code: {
      type: String,
      code: ""
    }
  },
  created() {
    this.isAdmin = sessionStorage.getItem("isAdmin");
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  },
  data() {
    return {
      tableHeight:0,
      isShowAddChild:true,
      isAdmin: -1,
      ruleForm: { title: "", file_arr: [] },
      rules: {
        // title: [
        //   { required: true, message: "请输入文件标题", trigger: "blur" },
        //   { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        // ],
        file_arr: [{ type: "array", required: true, message: "请选择文件", trigger: "blur" }]
      },
      fileListAdd: [],
      hideUploadAdd: false,
      dialogVisible: false,
      // projectId: "",
      total: 0,
      currentPage: 1,
      disabled: false,
      formData: { typeGroupId: "", typeId: "", code: "" },
      typeOptions: [],
      targetOptions: [],
      statusOptions: [
        { id: 1, name: "是" },
        { id: 0, name: "否" }
      ],
      tableData: [],
      treeDialogVisible: false,
      projectId: sessionStorage.getItem("projectId"),
      dialogVisible: false,
      isShowList: false, // 是否显示视图列表
      viewName: "", // 视图名称
      keywords: "", // 关键字搜索
      rightKeywords: "", // 自定义右侧树关键字搜索
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      isShowSinglePage: true, // 默认显示单测点页面
      // isInit: '',
      pointName: "", // 测点名称
      // 视图tree
      viewTreeData: [],
      // 默认选中的节点
      defaultKeys: [],
      // 默认展开的节点
      defaultExpandKeys: [],
      defaultProps: {
        children: "children",
        label: "treeName"
      },
      // 是否点击编辑
      isEdit: false,
      // 编辑的内容
      inputValue: "",
      // 视图下拉数据
      viewList: [],
      // 自定义 左树
      leftData: [],
      // 自定义 右树
      rightData: [],
      // 前两个视图
      firstTwoViewList: [],
      // 视图树 当前点击的视图
      currentViewId: "",
      // 自定义 当前点击的视图
      diyCurrViewId: "",
      defaultLeftKeys: [],
      defaultLeftExpandKeys: [],
      defaultLeftProps: {
        children: "children",
        label: "treeName"
      },
      defaultRightKeys: [],
      defaultRightProps: {
        children: "children",
        label: "targetName"
      },
      // 自定义 左侧视图结构树当前选中的节点
      diyLeftCurrentSelect: {},
      // 视图列表id
      viewListIds: [],
      // 仅当前点击的节点可以增改删
      dblClickNodeArr: [],
      // 双击树 编辑
      dbClickInputValue: "",
      // 测点id
      pointId: "",
      units: "",
      // 传给多测点对比分析页面的数据
      sendData: [],
      // 当前选中的测点
      // currSelect: {},
      // 是否显示checkbox
      isShowCheckbox: false,
      currentEditNode: {},
      isHandleEdit: false,
      getExpandId: "",
      // 是否处于添加
      isAddFlag: false,
      currEditId: "",
      currDblClickId: "",
      // 当前点击的操作按钮 需要高亮
      activeItem: ""
    };
  },
  mounted() {
    this.projectId = Number(this.$store.state.projectId);
    this.currentPage = this.curPage;

    this.formData.typeGroupId = Number(this.typeGroupId) || "";
    this.formData.typeId = Number(this.typeId) || "";
    this.formData.code = this.code || "";
    console.log("测点设备路由", this.$route);
    this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;

    this.getPointTypes();
    this.getData();
  },
  methods: {
    importHandle() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.dialogVisible = true;
    },
    // 获取默认结构树
    getDefaultTree(id) {
      this.$axios.get(`${this.baseURL}stress/target/${this.projectId}?viewId=${id}&name=${this.rightKeywords}`).then((res) => {
        console.log("获取自定义右侧默认结构树", res);
        this.rightData = res.data.data;
        this.defaultRightKeys = [res.data.data[0].targetId];
      });
    },
    // 获取自定义视图树（左侧）
    getDiyTree(id) {
      this.$axios.get(`${this.baseURL}stress/view/tree/${id}`).then((res) => {
        this.leftData = res.data.data;
      });
    },
    // 获取历史列表
    getViewsList() {
      this.$axios.get(`${this.baseURL}stress/view/${this.projectId}`).then((res) => {
        this.viewListIds = [];
        console.log("获取历史列表数据", res);
        let detail = JSON.parse(JSON.stringify(res.data.data));
        detail.forEach((item) => {
          item.flag = false;
          this.viewListIds.push(item.id);
        });
        this.viewList = detail;
        this.currentViewId = detail[0].id;
        // this.viewName = detail[0].viewName
      });
    },
    // 结构树设置
    handleTreeOperate() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.isShowList = false;
      this.treeDialogVisible = true;
      this.diyLeftCurrentSelect = {};
      this.getViewsList();
      setTimeout(() => {
        this.diyCurrViewId = this.viewList[0].id;
        this.viewName = this.viewList[0].viewName;
        this.getDefaultTree(this.currentViewId);
        this.getDiyTree(this.currentViewId);
      }, 500);
    },
    // 关闭结构树弹窗
    handleDialogClose() {
      this.treeDialogVisible = false;
      this.leftData = [];
      this.viewName = "";
    },
    // 点击新增icon
    handleAddIcon() {
      // this.viewName = ''
      this.isAddFlag = !this.isAddFlag;
    },
    // 点击结构树列表icon
    handleListBtnClick() {
      this.isShowList = !this.isShowList;
      this.currEditId = "";
    },
    // 上下移通用函数
    sortViewList(ids) {
      console.log(ids);
      this.$axios.post(`${this.baseURL}stress/sortView`, ids).then((res) => {
        if (res.status == 200) {
          this.getViewsList();
        }
      });
    },
    // 视图列表 上移
    handleGoUp(item, index) {
      console.log("上移", item, "index", index);
      this.viewListIds.splice(index, 1);
      this.viewListIds.splice(index - 1, 0, item.id);
      console.log(this.viewListIds);
      this.sortViewList(this.viewListIds);
      // 上移到了第一个
      setTimeout(() => {
        if (this.currentViewId == item.id) {
          this.getDiyTree(this.currentViewId);
          this.getDefaultTree(this.currentViewId);
        }
      }, 500);
    },
    // 视图列表 下移
    handleGoDown(item, index) {
      console.log("下移", item, "index", index);
      this.viewListIds.splice(index, 1);
      this.viewListIds.splice(index + 1, 0, item.id);
      console.log(this.viewListIds);
      this.sortViewList(this.viewListIds);
    },
    // 视图列表 编辑
    handleEditIcon(item, index) {
      this.isEdit = true;
      console.log("编辑", item, "index", index);
      console.log("当前展示的id", this.diyCurrViewId);
      this.inputValue = item.viewName;
      this.currEditId = item.id;
    },
    // 视图列表 删除
    handleDeleteIcon(item, index) {
      console.log("删除", item, "index", index);
      this.$confirm(`确认删除${item.viewName}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}stress/view/${item.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getViewsList();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 视图列表 编辑之后回车事件
    handleEnterEvent(item) {
      // console.log(item, this.diyCurrViewId, this.inputValue)
      this.$axios.put(`${this.baseURL}stress/view/name/${item.id}?viewName=${this.inputValue}`).then((res) => {
        if (res.data.status == 200) {
          this.$message.success("修改成功");
          if (this.diyCurrViewId == item.id) {
            this.viewName = this.inputValue;
          }
          this.currEditId = "";
          this.getViewsList();
        } else {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    // 新增结构树 回车事件
    handleAddView() {
      this.$axios.post(`${this.baseURL}stress/check/view/name?projectId=${this.projectId}&viewName=${this.viewName}`).then((res) => {
        console.log("检查新增视图名称是否重复", res);
        if (res.data.status == 0) {
          this.$message.warning(res.data.errMsg);
          return;
        } else {
          let data = {
            projectId: this.projectId,
            viewName: this.viewName
          };
          this.$axios.post(`${this.baseURL}stress/add/view`, data).then((res) => {
            if (res.data.status == 200) {
              this.$message.success("新增结构树成功");
              this.getViewsList();
              this.diyCurrViewId = res.data.data;
              this.leftData = [];
              this.isAddFlag = false;
              this.getDefaultTree(res.data.data);
              this.getDiyTree(res.data.data);
            }
          });
        }
      });
    },
    // 点击视图
    handleViewClick(item) {
      if (this.currEditId == item.id) {
        return;
      }
      this.viewName = item.viewName;
      this.diyCurrViewId = item.id;
      this.isShowList = false;
      this.getDiyTree(item.id);
      this.getDefaultTree(item.id);
      this.dblClickNodeArr = [];
    },
    // 点击左树
    handleLeftNodeClick(data, node) {
      if(node.level >= 4){
        this.isShowAddChild = false;
      }else{
        this.isShowAddChild = true;
      }
      console.log("dadadad",node)
      if (this.currDblClickId == data.id) {
        return;
      }
      this.currDblClickId = "";
      this.diyLeftCurrentSelect = data;
    },
    // 增加父节点
    handleAddTop() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择结构树");
        return;
      }
      console.log("自定义弹窗当前选择的视图id", this.diyCurrViewId);
      this.activeItem = "addParent";
      this.$axios.post(`${this.baseURL}stress/add/tree/node/${this.diyCurrViewId}`).then((res) => {
        console.log("增加父节点", res);
        if (res.status == 200) {
          this.getDiyTree(this.diyCurrViewId);
          this.activeItem = "";
        }
      });
    },
    // 增加子节点
    handleAddChildNode() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择结构树");
        return;
      }
      console.log("dasdasd",this.diyLeftCurrentSelect);
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      this.activeItem = "addChild";
      this.$axios.post(`${this.baseURL}stress/add/tree/child/node/${this.diyLeftCurrentSelect.viewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
        if (res.status == 200) {
          this.$message.success("新增子节点成功");
          this.activeItem = "";
          this.getDiyTree(this.diyLeftCurrentSelect.viewId);
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.dblClickNodeArr = [];
        }
      });
    },
    // 升级
    handleUpgrade() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      // 如果节点是父节点，不需要升级
      // if (this.diyLeftCurrentSelect.treeParentId == 0) {
      //   this.$message.warning("该节点已是最高级别，不需要升级");
      //   return;
      // }
      console.log("节点升级传参", this.diyCurrViewId, this.diyLeftCurrentSelect.id);
      this.activeItem = "upgrade";
      this.$axios.post(`${this.baseURL}stress/tree/upgrade/${this.diyCurrViewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点升级", res);
        if (res.data.status == 200) {
          this.$message.success("节点升级成功");
          this.activeItem = "";
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.getDiyTree(this.diyCurrViewId);
        } else {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    // 降级
    handleDownGrade() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      // 如果节点是叶子节点，不需要降级
      // if (this.diyLeftCurrentSelect.treeParentId > 0 && !this.diyLeftCurrentSelect.children) {
      //   this.$message.warning("该节点已是最低级别，不需要降级");
      //   return;
      // }
      this.activeItem = "downgrade";
      this.$axios.post(`${this.baseURL}stress/tree/demotion/${this.diyCurrViewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点降级", res);
        if (res.data.status == 200) {
          this.$message.success("节点降级成功");
          this.activeItem = "";
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.getDiyTree(this.diyCurrViewId);
        } else {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    // 上移
    handleNodeUp() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      this.activeItem = "up";
      this.$axios.get(`${this.baseURL}stress/tree/node/up/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点上移", res);
        if (res.data.status == 200) {
          this.$message.success("节点上移成功");
          this.activeItem = "";
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.getDiyTree(this.diyCurrViewId);
        } else {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    // 下移
    handleNodeDown() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      this.activeItem = "down";
      this.$axios.get(`${this.baseURL}stress/tree/node/down/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点下移", res);
        if (res.data.status == 200) {
          this.$message.success("节点下移成功");
          this.activeItem = "";
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.getDiyTree(this.diyCurrViewId);
        } else {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    // 编辑树节点
    handleEditTreeNode() {
      console.log("0000000000000000000", this.diyLeftCurrentSelect);
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择要编辑的节点");
        return;
      }
      this.activeItem = "edit";
      this.currDblClickId = this.diyLeftCurrentSelect.id;
      this.dbClickInputValue = this.diyLeftCurrentSelect.treeName;
    },
    // 删除
    handleDeleteTreeNode() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      this.activeItem = "delete";
      this.$confirm(`确认删除${this.diyLeftCurrentSelect.treeName}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then((res) => {
        console.log(this.diyLeftCurrentSelect);
        this.$axios.delete(`${this.baseURL}stress/delete/view/tree/${this.diyLeftCurrentSelect.viewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.activeItem = "";
            this.diyLeftCurrentSelect = {};
            this.getDiyTree(this.diyCurrViewId);
            this.getDefaultTree(this.diyCurrViewId);
          }
        });
      });
    },
    // 从左到右
    handleBtnFromLeft() {
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      let data = {
        ids: [this.diyLeftCurrentSelect.id],
        viewId: this.diyCurrViewId
      };
      console.log(data);
      this.$axios.post(`${this.baseURL}stress/untie`, data).then((res) => {
        if (res.status == "200") {
          this.$message.success("设置成功");
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.getDiyTree(this.diyCurrViewId);
          this.getDefaultTree(this.diyCurrViewId);
        }
      });
    },
    // 从右到左
    handleBtnFromRight() {
      let rightChecked = this.$refs.rightTree.getCheckedNodes();
      if (!rightChecked.length) {
        this.$message.warning("请选择测点数据");
        return;
      }
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择结构树节点");
        return;
      }
      let data = {
        id: this.diyLeftCurrentSelect.id,
        list: rightChecked,
        viewId: this.diyCurrViewId
      };
      this.$axios.post(`${this.baseURL}stress/relevance`, data).then((res) => {
        if (res.status == "200") {
          this.$message.success("设置成功");
          this.defaultLeftExpandKeys = [this.diyLeftCurrentSelect.id];
          this.diyLeftCurrentSelect = {};
          this.rightKeywords = "";
          this.getDiyTree(this.diyCurrViewId);
          this.getDefaultTree(this.diyCurrViewId);
        }
      });
    },
    // 双击编辑ing input事件
    handleInputEvent(value, node, data) {
      this.dbClickInputValue = value;
      // data.label = value
    },
    // 双击节点事件
    handleDblClick(node, data, event) {
      this.currDblClickId = data.id;
      this.dbClickInputValue = data.treeName;
    },
    // 双击 编辑节点内容 回车提交
    handleViewEditSubmit(node, data) {
      let str = this.dbClickInputValue.replace(/\#/g, "%23");
      console.log("发送给后端的名称-------", str);
      this.$axios.put(`${this.baseURL}stress/update/view/tree/${data.id}?treeName=${str}`).then((res) => {
        console.log("结构树编辑节点名称", res);
        if (res.status == 200) {
          this.$message.success("修改成功");
          this.activeItem = "";
          this.getDiyTree(data.viewId);
          this.currentEditNode = {};
          this.dblClickNodeArr = [];
          this.defaultLeftExpandKeys = [this.currDblClickId];
          this.currDblClickId = "";
          this.diyLeftCurrentSelect = {};
        }
      });
    },
    // 右侧树 实时搜索关键字
    handleRightKeyWordsInput() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择结构树");
        return;
      }
      this.getDefaultTree(this.diyCurrViewId);
    },
    // 右侧树 过滤关键字
    filterRightNode(value, data) {
      if (!value) return true;
      return data.targetName.indexOf(value) !== -1;
    },
    // 右侧树 模糊查询
    handleRightFilter() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择结构树");
        return;
      }
      this.getDefaultTree(this.diyCurrViewId);
    },

    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd;
      console.log("filelist", this.fileListAdd);
      if (this.fileListAdd.length <= 0) {
        this.$message.error("您还没有选择文件!");
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let formData = new FormData();
          // formData.append("title", this.ruleForm.title);
          this.fileListAdd.map((item) => {
            formData.append("multipartFile", item.raw);
          });

          // this.$axios.post(`${this.baseURL}sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}`, formData).then((res) => {
          this.$axios.post(`${this.baseURL}base/baseTarget/import/${this.projectId}`, formData, { headers: { "content-type": "multipart/form-data" } }).then((res) => {
            // this.$axios.post(`http://192.168.16.218:8197/base/baseTarget/import/${this.projectId}`, formData, { headers: { "content-type": "multipart/form-data" } }).then((res) => {
            this.loadingPage.close();
            this.$message({
              message: "上传成功",
              type: "success"
            });

            this.handleClose();
            this.currentPage = 1;
            this.getData();
          });
        }
      });
    },
    //返回
    back() {
      this.handleClose();
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      console.log("file", file, "filelist", fileList);
      let ext = file.name.match(/\.[^\.]+$/)[0];
      console.log("ext", ext);
      if (ext != ".xls" && ext != ".xlsx") {
        this.$message.error("文件格式不正确!");
        fileList.splice(-1, 1);
      }

      // 图片大小限制
      // const isLt20M = file.size / 1024 / 1024 < 50;
      // if (!isLt20M) {
      //   this.$message.error("文件太大了!");
      //   fileList.splice(-1, 1);
      // } else {
      this.fileListAdd = fileList;
      this.ruleForm.file_arr = this.fileListAdd;
      this.$refs.ruleForm.validate((valid) => {});
      // }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      // this.$message({
      //   message: "上传文件超出限制个数！",
      //   type: "warning"
      // });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      this.dialogVisible = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        console.log("监测类型返回", res);
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ id: "", name: "全部" });
          this.typeOptions = res.data;
          this.getTargetOptions();
        }
      });
    },
    getTargetOptions() {
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId?typeGroupId=${this.formData.typeGroupId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ id: "", name: "全部" });
          this.targetOptions = res.data;
        }
      });
    },
    getData(val) {
      if (val) {
        this.currentPage = 1;
        this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code } });
      }
      this.$axios
        .get(`${this.baseURL}base/baseTarget/select/${this.currentPage}/10/${this.projectId}`, {
          params: this.formData
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            this.total = res.data.total;

            console.log("total", this.total, res, Math.ceil(this.total / 10) < this.currentPage);
            if (this.total && Math.ceil(this.total / 10) < this.currentPage) {
              this.currentPage = Math.ceil(this.total / 10);
              this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });
            }
            this.tableData = res.data.records;
          }
        });
    },
    resetData() {
      this.formData = { typeGroupId: "", typeId: "" };
      this.handleCurrentChange(1);
      // this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage } });
      this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage } });
      this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });

      this.getData();
    },
    changeStatus(val) {
      if (this.total % 10 == 1) this.currentPage -= 1;
      if (val.selectedRel == 1) {
        // 设为自选
        this.$axios.post(`${this.baseURL}base/baseTargetSelectedRel/${this.projectId}/${val.id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) this.getData();
        });
      } else {
        // 取消自选
        this.$axios.delete(`${this.baseURL}base/baseTargetSelectedRel/${val.id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) this.getData();
        });
      }
      // this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage } });
      this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });
    },
    handlePoint() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      // this.$router.push({ path: "/handlePoint", query: { active: 1, currentPage: this.currentPage } });
      this.$router.push({ path: "/handlePoint", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });
    },
    showDetail(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$router.push({ path: "/viewPoint/" + id, query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code, t: Date.now() } });
    },
    handleEdit(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      this.$router.push({
        path: "/handlePoint",
        query: {
          id,
          type: "edit",
          currentPage: this.currentPage,
          typeGroupId: this.formData.typeGroupId,
          typeId: this.formData.typeId,
          code: this.formData.code
        }
      });
    },
    handleDel(id) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      let curpag = -1;
      if (this.tableData.length == 1 && this.currentPage > 1) {
        curpag = this.currentPage - 1;
      }
      this.$confirm("确认删除该测监测类型？", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.put(`${this.baseURL}base/baseTarget/del/${id}`).then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            if (curpag >= 0) {
              this.currentPage = curpag;
              // this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage } });
              this.$router.push({ path: "/devManage", query: { active: 1, currentPage: this.currentPage, typeGroupId: this.formData.typeGroupId, typeId: this.formData.typeId, code: this.formData.code } });
            }
            this.getData();
          }
        });
      });
    },
    getSize(){
        this.tableHeight = window.innerHeight - 385
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

td .icon {
  font-size: 22px;
  cursor: pointer;
}

td .view-icon {
  font-size: 24px;
  // color:rgb(134, 232, 255);
  // &:hover {
  //   color: #3997AD;
  // }
}

.icon:nth-child(2) {
  margin: 0 10px;
  // color: #DBF804;
  // &:hover {
  //   color:#98B300;
  // }
}

.icon:last-child {
  color: #f7f7f7;

  &:hover {
    color: #c3c3c3;
  }
}

.el-table {
  margin-bottom: 35px;
}

.finish {
  color: #86e8ff;
}

.el-form-item {
  margin: 0;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}

::v-deep .my-dialog div.el-dialog {
  width: 850px !important;
  height: 800px;
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    padding: 0 20px 30px 20px;
  }
}
::v-deep .el-dialog__header {
  border: none !important;
  .el-dialog__title {
    font-size: 16px;
  }
}
.add-wrapper {
  height: 65px;
  line-height: 35px;
  border-top: 1px solid #194582;
  border-bottom: 1px solid #194582;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  .add-name {
    width: 55%;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    .my-divider {
      margin: 0 20px;
      background: #b2c3f8;
    }
    ::v-deep .el-input {
      width: 70%;
      input {
        border: 1px solid #2260cb;
        border-radius: 4px;
      }
    }
  }
  .list-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    justify-content: end;
    align-items: center;
    text-align: right;
    i {
      font-size: 31px;
      color: #5474ac;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    img {
      display: inline-block;
    }
    .select-ul {
      position: absolute;
      top: 40px;
      width: 320px;
      height: 200px;
      overflow: auto;
      z-index: 999;
      padding-right: 5px;
      background: #102c63;
      font-size: 14px;
      font-weight: 400;
      color: #8b919e;
      line-height: 2;
      border-radius: 8px;
      box-shadow: 0px 2px 9px 0px rgba(2, 25, 29, 0.67);
      li {
        display: flex;
        padding-left: 15px;
        .icon {
          width: 150px;
          height: 100%;
          span {
            margin-right: 5px;
          }
        }
        .name {
          /* width: 50%; */
          flex: 1;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .my-edit-input {
            width: 100%;
            z-index: 9999;
          }
        }
      }
      li:hover {
        cursor: pointer;
        background: rgba(50, 116, 255, 0.24);
        color: #fff;
      }
    }
    img:hover {
      cursor: pointer;
    }
  }
}
.btns-wrapper {
  height: 30px;
  display: flex;
  margin: 10px 0;
  align-items: center;
  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #2170ff;
    &:hover {
      cursor: pointer;
      color: #4fb2ff;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .active-icon.icon-wrapper {
    color: #4fb2ff;
  }
  img {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  img:nth-child(2) {
    margin: 0 10px;
  }
  img:hover {
    cursor: pointer;
  }
}
.tree-wrapper {
  height: calc(100% - 115px);
  display: flex;
  .dialog-left-tree {
    width: 40%;
    padding: 10px 20px;
    .my-left-tree {
      height: calc(100% - 50px);
      overflow-y: auto;
      background: none;
      margin-top: 10px;
      color: #fff;
      .my-edit-input.el-input {
        width: 220px;
      }
    }
  }
  .dialog-right-tree {
    width: calc(60% - 200px);
    padding: 10px 20px;
    .my-right-tree {
      height: calc(100% - 50px);
      overflow-y: auto;
      background: none;
      margin-top: 10px;
      color: #fff;
    }
  }
  .dialog-left-tree,
  .dialog-right-tree {
    background: linear-gradient(180deg, rgba(0, 35, 89, 0.67) 1%, rgba(19, 18, 68, 0.65));
    border: 1px solid #2667db;
  }
  .dialog-btn {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .dialog-btn-group {
      text-align: center;
      line-height: 5;
      img {
        display: inline-block;
      }
      .el-button {
        width: 160px;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
.custom-tree-node {
  width: 100%;
  display: flex;
  .node-icon {
    width: 100px;
    color: #2772f0;
    /* font-size: 16px; */
    i:nth-child(2) {
      margin: 0 10px;
    }
  }
  .node-name {
    flex: 1;
  }
}
::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: rgba(50, 116, 255, 0.24) !important;
}
::v-deep .el-tree-node__content {
  height: 34px;
}
</style>
